<template>
  <div class="anonymous-page-success">

    <div class="content-close">
      <button @click="close">
        <i class="material-icons">close</i>
      </button>
    </div>


    <template v-if="!isOnPlatform && merchant">
      <div class="anonymous-page-header">
        <h1>Votre compte est <br> sécurisé !</h1>
        <span>Vous pourrez désormais commander en 1 clic sur {{merchant.name}} grâce à votre compte Delicity</span>
      </div>

      <div class="anonymous-container">
        <div class="favorite-section">
          <div class="favorite-section__content">
            <div class="content__header">
              <img :src="merchant.logo.path" alt="">
              <div class="text">Enregistrez à vos favoris {{merchant.name}}</div>
            </div>
            <span>Accédez au site plus rapidement pour votre prochaine commande</span>
          </div>
          <div class="favorite-section__footer" @click="addToBookmark" v-if="hasShare">
            Ajouter au favoris
          </div>
        </div>
        <div class="app-section">
          <div class="content__header">
            <img src="/img/delicity-appx114.png" alt="">
            <div class="text">Retrouvez aussi {{merchant.name}} sur l’app Delicity</div>
          </div>
          <div class="content__footer">
            <a href="https://t.delicity.co/CSNBCW" target="_blank"><img src="/img/appstore.svg" alt=""></a>
            <a href="https://t.delicity.co/KW9I1S" target="_blank"><img src="/img/playstore.svg" alt=""></a>
          </div>
        </div>
      </div>



    </template>
    <template v-else>
      <div class="anonymous-page-header-center">
        <img src="/img/delicity_circle.png" alt="">
        <h1>Bienvenue chez Delicity !</h1>
        <span>Vous pouvez maintenant commander en 1 clic chez l’ensemble de nos restaurants partenaires </span>
      </div>
      <div class="anonymous-container">
        <div class="app-section">
          <div class="content__header">
            <img src="/img/delicity-appx114.png" alt="">
            <div class="text">Téléchargez l’application Delicity</div>
          </div>
          <div class="content__footer">
            <a href="https://t.delicity.co/CSNBCW" target="_blank"><img src="/img/appstore.svg" alt=""></a>
            <a href="https://t.delicity.co/KW9I1S" target="_blank"><img src="/img/playstore.svg" alt=""></a>
          </div>
        </div>
      </div>
    </template>




  </div>
</template>


<script>
import '../../scss/app_mobile.scss';
import '../../scss/Mobile/anonymous_pages.scss';
import {mapActions, mapGetters} from "vuex";
import Config from "@/config";

export default {
  name: "AnonymousSuccess",
  data(){
    return {
      isOnPlatform: false,
      user: null,
      hasShare: false,
      isPageLoading: false
    }
  },
  methods: {
    ...mapActions(['getAccountAction', 'getMerchant']),
    close(){
      if(this.$route.query.redirect){
        this.$router.push(this.$route.query.redirect);
      }
      else if(localStorage.getItem('anonymous_account_redirect')){
        this.$router.push(localStorage.getItem('anonymous_account_redirect'));
      }
      else{
        this.$router.push('/');
      }
    },

    addToBookmark(){
      navigator.share({
        text: this.merchant.name,
        url: location.href
      }).then(() => {
      }).catch(console.error);
    }
  },
  computed: {
    ...mapGetters(['merchant'])
  },
  async mounted() {

    this.isPageLoading = true;
    this.isOnPlatform = (location.host === Config.mainHost || location.host === 'delicity.com');
    if(!this.isOnPlatform){
      await this.getMerchant({slug: location.host});
    }
    this.user = await this.getAccountAction();
    this.isPageLoading = false;

    if(navigator.share){
      this.hasShare = true;
    }

    if(this.user.anonymous === true){
      this.$router.push('/');
    }
  }
}
</script>


